<template>
  <div class="w-100 " style="padding: 15px 10px;">
    <section class="w-100 collections " v-if="!isOpenCollectionProductComp">
      <div class=""
        v-if="areNotAvailableCollections" style="height: 70vh; overflow-y: scroll; overflow-x: hidden;">
        <LoaderComp v-if="isLoadingImport"/>
        <div v-else class=" row question-not-font flex-column d-flex justify-content-center align-items-center">
        <LazyImage :src="'/assets/img/noData.svg'" alt="" style="width: 15rem;" class="mt-5" />
        <div class="row text-center px-2">
          <div class="col-12">
             <h3 class="pt-5" style="   font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;

letter-spacing: 0.04em;

color: #4D4950;">No Collections found!</h3>
          </div>
          <!-- <div class="col-12">
            <h5 v-if="getUserType !='wix'">You can <a href='javascript:void(0)' 
            @click="()=>{isLoadingImport=true; $emit('importItems',{title:'saveCollections',callBackFunction:()=>{isLoadingImport=false;}})}">
            import collections</a> from your store</h5>
          </div> -->
        </div>
      </div>
      
      </div>
      <b-skeleton type="input" v-else-if=isLoading></b-skeleton>
      <div class="" v-else>
        <div class="collectionSearchInput col-12 row align-items-center mx-1 pr-3 py-1">
          <div style="cursor: pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9.5 3C11.2239 3 12.8772 3.68482 14.0962 4.90381C15.3152 6.12279 16 7.77609 16 9.5C16 11.11 15.41 12.59 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.59 15.41 11.11 16 9.5 16C7.77609 16 6.12279 15.3152 4.90381 14.0962C3.68482 12.8772 3 11.2239 3 9.5C3 7.77609 3.68482 6.12279 4.90381 4.90381C6.12279 3.68482 7.77609 3 9.5 3ZM9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C12 14 14 12 14 9.5C14 7 12 5 9.5 5Z" fill="#73738D"/>
</svg>
            <input type="text" class="mx-2 flex-grow-1" v-model="searchCollection"
              placeholder="Search for Collection" />
          </div>

        </div>
       

      </div>

      <div v-if=isLoading class="col-12  mt-4 d-flex justify-content-end">
        <b-skeleton class="w-25"></b-skeleton>
      </div>
      <div v-else class="col-12  mt-4 d-flex justify-content-end">

        <div class="filterBtn" v-if="!areNotAvailableCollections && getCollections.length > 0"
          @click.stop="bulkSelectCollections">
          {{ isSelectAll?'Select All': 'Unselect All' }}</div>
      </div>
      <div class="w-100 p-3 collectionBox" v-if=isLoading style="border:none">
        <div class="skel">
          <div class="mb-2 py-1" v-for="index in 5" :Key="index">
            <div class="d-flex  align-items-center">
              <b-skeleton type="avatar" animation="throb"></b-skeleton>
              <b-skeleton type="input" class="ml-2 w-86" animation="throb"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="w-100 p-3 collectionBox" v-if="(!areNotAvailableCollections) && getCollections.length > 0" style="border:none">
        <div class="mb-3 py-1,collection-row" :class="isSelectedProduct(collection) ? 'selectedProduct' : null"
          v-for="collection, prodIndex in getCollections" :key="collection.id">
          <div class="d-flex align-items-center collection">
            <div class="d-flex  px-0 align-items-center" @click="selectProduct(collection)" style="flex:0.9;cursor:pointer">
              <div class="">
                <div class="custom-control custom-checkbox" role="group">
                  <input type="checkbox" :id="`quest-${prodIndex}`" class="custom-control-input position-static"
                    aria-label="Never" :value="{
                      collection_id: collection.id,
                      question_option_id:
                        question.options[
                          selected.optionIndex
                        ].id,
                    }" v-model="
  question.options[
    selected.optionIndex
  ].selected_collections
"
 @change="selectProduct(collection)" 
 />
                  <label :for="`quest-${prodIndex}`" class="custom-control-label"></label>
                </div>
              </div>
              <div class="img">
                <div v-if="checkFileType(collection.image) == 'video'">
                  <video ref="videoRef" :src="`${collection.image}`" alt="" class="img-thumbnail mr-2" style="
                                      height: 50px;
                                      width: 50px;
                                      border-radius: 50%;
                                      object-fit: cover;
                                    " controls></video>
                </div>
                <LazyImage :src="collectionImage(collection.image)" :key="1" alt="" class="product-image" />
              </div>
              <div class="singleProduct d-flex align-items-center pl-2">
                <p class="title text-left">
                  {{ collection.title }}
                </p>
              </div>
            </div>
             <div class=" text-center" style="flex:0.1">
              <button v-b-tooltip.hover title="Product Collections" class="btn" @click.stop="openVariant(collection.id)">
             <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 19H13L11 21H4C3.5 21 2.97 20.79 2.59 20.41C2.21 20.03 2 19.5 2 19V9H4V19ZM17.63 5.84C17.27 5.33 16.67 5 16 5H8C6.9 5 6 5.9 6 7V15C6 16.1 6.9 17 8 17H16C16.67 17 17.27 16.66 17.63 16.15L22 11L17.63 5.84Z" fill="#4D4950"/>
</svg>
              </button>
            </div>
         

          </div>

        </div>
      
      </div>
    
      <div class="w-100 p-3 collectionBox" v-if="!this.isLoading && collections.all.length > 0 && getCollections <= 0"
        style="border:none">
        <div class="d-flex flex-column h-100 justify-content-center align-items-center text-center mb-2 py-1">
          <div class="btn" v-b-tooltip.hover.top="'Reset Filter'" @click="searchCollection = ''"> <i
              class="fa-solid fa-arrow-rotate-right"></i></div>
          <h5>No collections were found matching your filter </h5>
        </div>
      </div>
    </section>
    <collection-variant @openVariant=openVariant v-else @backFromVariant="backFromVariant()" :isLoadingProducts="isLoadingCollectionProducts"
      :collectionProducts="collectionProducts" :selected="selected" :isNextCollectionProduct="isNextCollectionProduct"></collection-variant>
  </div>
</template>
<script>
import LoaderComp from "../../component/LoaderComp.vue";
import axios from 'axios'
import CollectionVariant from './CollectionVariant.vue'
import { mapGetters } from "vuex";
export default {
  props: {
    collections: Object,
    selected: Object,
    question: Object,
    isLoading: Boolean,
    products:Array
  },
  components: {
    CollectionVariant,
    LoaderComp
  },
  computed: {
    areNotAvailableCollections() {
      return this.isLoading == false && this.collections.all.length <= 0 ? true : false
    },
    ...mapGetters(["getEditorSettings", "GetTokenFromMetaTag", "getQuizID","getNavbarData"]),
  getUserType(){
      return this.getNavbarData.userType
    },
    getSortedCollection(){
      if(this.question.options[this.selected.optionIndex].selected_collections.length){
         let sortedCollection = []

        //  find Selected Products from All Products
          this.question.options[this.selected.optionIndex].selected_collections.forEach(selectedSingleCollection=>{
               const selectedCollection = this.collections.all.find(collection => collection.id === selectedSingleCollection.collection_id)
  if (selectedCollection) {
    sortedCollection.push(selectedCollection)
  }
          })

          // Find remaining non selected Products
          const otherCollection =  this.collections.all.filter(collection => !this.question.options[this.selected.optionIndex].selected_collections.some(selectedSingleCollection => selectedSingleCollection.collection_id === collection.id))
          sortedCollection = sortedCollection.concat(otherCollection)
         

         return sortedCollection
      }else{
        return this.collections.all
      }
    },

    getCollections() {
     
      return this.getSortedCollection.filter(item => {
        return ((item.title.toLowerCase().includes(this.searchCollection.toLowerCase())))
      });
    }
  },
  data() {
    return {
      isLoadingImport:false,
      isLoadingBtn:false,
      isOpenCollectionProductComp: false,
      skeletonCount: 5,
      searchCollection: '',
      isSelectAll: true,
      showResetBtn: false,
      collectionProducts:[],
      isLoadingCollectionProducts:false,
      isNextCollectionProduct:null,
    }
  },
  methods: {
 
  
   async openVariant(id='') {

    this.isLoadingCollectionProducts=true;
      this.isOpenCollectionProductComp = true
   
       await axios.post('collection/products',{collection_id:id}).then(resp=>{

      this.collectionProducts = resp.data.data.products;
      }).catch(()=>[])
        this.isLoadingCollectionProducts=false;
    },
    backFromVariant() {
      this.collectionProducts = [];
      this.isOpenCollectionProductComp = false
    },
    bulkSelectCollections() {
      let collections = this.getCollections;
      if (this.isSelectAll) {
        this.isSelectAll = false;
        let selected_option_collection = [];
        collections.forEach(collection => {
          selected_option_collection.push({
            collection_id: collection.id,
            question_option_id: this.question.options[
              this.selected.optionIndex
            ].id
          })
        })
        this.question.options[
          this.selected.optionIndex
        ].selected_collections = selected_option_collection
      }
      else {
        this.question.options[
          this.selected.optionIndex
        ].selected_collections = [];
        this.isSelectAll = true;
      }

    },


    isSelectedProduct(collection) {

      const findIndex = this.question.options[this.selected.optionIndex].selected_collections.findIndex((x) => x.collection_id == collection.id);
      if (findIndex > -1) {
        return true;
      } else {
        return false;
      }
    },

    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    collectionImage(image) {
      if (image)
        return image.startsWith('upload') ? '/' + image : image
      return 'https://images.quizell.com/default/default_image.png'
    },
     selectProduct(collection) {

      

      // question.options[selected.optionIndex].selected_products
      const index = this.question.options[
       this.selected.optionIndex
      ].selected_collections.findIndex(
        (productOption) => productOption.collection_id == collection.id
      );

      if (index > -1) {
        
        this.question.options[
       this.selected.optionIndex
      ].selected_collections.splice(index, 1);
      } else {
        const obj = {};
        obj.collection_id = collection.id;
        obj.question_option_id =
          this.question.options[
       this.selected.optionIndex
      ].id;

        this.question.options[
       this.selected.optionIndex
      ].selected_collections.push(obj);
      }
    },
  }
}
</script>
<style>
.collection-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  background-size: cover;
  padding: 0.25rem;
  background-color: #fff;
}

.collection-icon {
  color: #4d1b7e;

}

.collection-icon:hover {
  color: black;
}


.collectionSearchInput {
  border: 0.8px solid #18191C14;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}

.collectionSearchInput input {
  background: transparent;
  border: none;
  outline: none;
}

.collectionSearchInput input:focus {
  border: none;
  outline: none;
}

.filterBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4d1b7e;
  cursor: pointer
}

.filterBtn:hover {
  color: black;

}

.w-86 {
  width: 86%;
}

.collectionBox {
  height: 50vh;
  /* border: 0.5px solid #b3afb6; */
  box-sizing: border-box;
  border-radius: 10px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.collectionBox .collection {
  border-radius: 6px;
}

.collectionBox .collection:hover {
  background: #b3afb614;
}

.collection-btn {
  display: none;
  font-size: 14px;
  border: none;
  outline: none;
}

.selectedProduct .collection:hover .collection-btn {
  fill: #4d1b7e !important;
  color: #4d1b7e !important;
}
.selectedProduct .collection:hover .collection-btn .collection-svg svg{
  fill: #4d1b7e !important;
}
.selectedProduct .collection:hover .collection-btn .collection-text {
  color: #4d1b7e !important;
}

.collection:hover .collection-btn {
  display: block;
}

.singleProduct .title {
  color: #18191c;
  font-weight: 500;
}

.singleProduct .price {
  color: #4d4950;
  font-weight: 300;
}
</style>